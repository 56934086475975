.blockquote-slider {
  max-width: 1200px;
  margin: 0 auto;

  .blockquote {
    @media (min-width: 640px) {
      padding: 0 calc(80 / var(--fz) * var(--fz-em));
    }
  }

  .uk-dotnav {
    margin-top: calc(60 / var(--fz) * var(--fz-em));

    >* {
      padding-left: calc(16 / var(--fz) * var(--fz-em));
    }

    >*>* {
      background: var(--text-color);
      opacity: 0.7;
      width: calc(14 / var(--fz) * var(--fz-em));
      height: calc(14 / var(--fz) * var(--fz-em));
    }

    >.uk-active>* {
      background: var(--header-h1-color);
      opacity: 1;
    }
  }

  .uk-slidenav {
    color: var(--text-color);
  }
}

.blockquote {
  &__container {
    display: flex;
    position: relative;
  }

  &__image {
    --quote-image-width: 70;

    @media (min-width: 960px) {
      --quote-image-width: 170;
    }

    position: absolute;
    width: calc(var(--quote-image-width) / var(--fz) * var(--fz-em));
    height: calc(var(--quote-image-width) / var(--fz) * var(--fz-em));
    top: 0;
    left: 0;
    border-radius: 50%;
    overflow: hidden;
    background: var(--z-gray-90);
    padding: calc(10 / var(--fz) * var(--fz-em));

    @media (min-width: 960px) {
      width: auto;
      flex-shrink: 0;
      position: inherit;
      /* padding: calc(20 / var(--fz) * var(--fz-em)); */
      max-width: calc(var(--quote-image-width) / var(--fz) * var(--fz-em));
      flex-basis: calc(var(--quote-image-width) / var(--fz) * var(--fz-em));
      margin-right: calc(40 / var(--fz) * var(--fz-em));
    }

    .icon {
      width: 100%;
      height: 100%;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      border-radius: 50%;
    }
  }

  &__body {
    flex-grow: 1;
  }

  &__title {
    min-height: calc(70 / var(--fz) * var(--fz-em));
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__person {
    --fz: 18;

    font-size: calc(var(--fz) / var(--fz-base) * var(--fz-em));
    font-weight: 600;
    line-height: 1.2;
    color: var(--header-h1-color);
    padding-left: calc(70 / var(--fz) * var(--fz-em));
    margin-left: calc(16 / var(--fz) * var(--fz-em));

    @media (min-width: 960px) {
      --fz: 32;

      padding-left: 0;
      margin-left: 0;
    }
  }

  &__content {
    max-height: 150px;
    margin-top: 20px;

    @media (min-width: 960px) {
      margin-top: 0;
    }
  }

  //     &__post {
  //         $fz: 14;

  //         font-size: em($fz, $fz_base);
  //         font-weight: 600;
  //         line-height: 1.22;
  //         color: $subtitle-text-color;
  //         margin-top: em(8, $fz);
  //         padding-left: em(69, $fz);
  //         margin-left: em(16, $fz);

  //         @media (min-width: 960px) {
  //             $fz: 18;

  //             font-size: em($fz, $fz_base);
  //             padding-left: 0;
  //             margin-left: 0;
  //         }

  //     }

  //     &__content {
  //         $fz: 14;

  //         font-size: em($fz, $fz_base);
  //         margin-top: em(24, $fz);
  //         line-height: 1.18;
  //         color: $subtitle-text-color;
  //         font-family: $f3;

  //         @media (min-width: 960px) {
  //             $fz: 22;

  //             font-size: em($fz, $fz_base);
  //             margin-top: em(24, $fz);
  //         }
  //     }
}